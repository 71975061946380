/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable camelcase */
const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');

const DisclaimerLinks = ({ links }) => (
  <div className="disclaimer-links">
    <span>
      {links.map(({ id, name, link }) => <a key={id} href={link} target="_blank">{name}</a>)}
    </span>
  </div>
);

DisclaimerLinks.propTypes = {
  links: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};

DisclaimerLinks.defaultProps = {
  links: [],
};

/**
 * Expose Something with i18n injection
 */
module.exports = injectI18n(DisclaimerLinks);
