const React = require('react');
const PropTypes = require('prop-types');
const classNames = require('classnames');
const IconSelector = require('../IconSelector');

const TagSpecialMessage = ({ label, iconColor, icon, borderColor }) => {
  const tagSpecialMessage = classNames(
    'tag-special-message',
    borderColor === 'lightBlue' ? 'tag-special-message__lightblue' : 'tag-special-message__green',
  );

  return (
    <div className={tagSpecialMessage}>
      <div className="tag-image">
        <IconSelector
          id={icon}
          attr={{
            color: iconColor,
          }}
        />
      </div>
      <span className="tag-label">{label}</span>
    </div>
  );
};

TagSpecialMessage.propTypes = {
  label: PropTypes.string,
};

TagSpecialMessage.defaultProps = {
  label: '',
};

module.exports = TagSpecialMessage;
