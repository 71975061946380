/* eslint-disable max-len */
const React = require('react');

const Shield = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="15"
    height="15"
    viewBox="0 0 15 15"
  >
    <path
      fillOpacity="0.45"
      d="M7.562.599l.383.317c1.92 1.59 3.81 2.374 5.68 2.374h.6v.6c0 5.633-2.165
      9.242-6.473 10.679l-.19.063-.19-.063C3.064 13.132.9 9.523.9 3.89v-.6h.6c1.87
      0 3.76-.783 5.68-2.374l.383-.317zm0 1.548c-1.8 1.4-3.62 2.179-5.455 2.32.135
      4.725 1.947 7.648 5.455 8.898 3.508-1.25
      5.32-4.173 5.455-8.898-1.835-.141-3.656-.92-5.455-2.32zm2.286
      2.895l.896.798-4.02 4.513-2.472-2.377.831-.865 1.574 1.513 3.191-3.582z"
    />
  </svg>
);

module.exports = Shield;
