/* eslint-disable no-restricted-globals */
/* eslint-env browser */
module.exports = {
  /**
   * Bind event to the selected element
   * @param element
   * @param name
   * @param callback
   */
  bindEventListener(element, name, callback) {
    if (element.addEventListener) {
      return element.addEventListener(name, callback, false);
    }
    return element.attachEvent(`on${name}`, callback);
  },
  /**
   * Check if the checkout is inside an iframe
   * @returns {boolean}
   */
  isIframe() {
    if (typeof window !== 'undefined') {
      return window.self !== window.top || window.location !== window.parent.location;
    }
    return false;
  },
};
