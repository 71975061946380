const React = require('react');
const PropTypes = require('prop-types');
const MobileLayout = require('./mobileLayout/mobileLayout');
const DesktopLayout = require('./desktopLayout/desktopLayout');
const SafePaymentMessage = require('../../../components/SafePaymentMessage');
const DisclaimerLinks = require('../../../components/DisclaimerLinks');

const deviceLayout = ({ device, children, i18n, disclaimerLinks }) => (
  <>{(device === 'desktop'
    ? (
      <>
        <DesktopLayout>{children}</DesktopLayout>
        <SafePaymentMessage i18n={i18n} />
        <DisclaimerLinks links={disclaimerLinks} />
      </>
    )
    : <MobileLayout>{children}</MobileLayout>)}
  </>
);

deviceLayout.propTypes = {
  device: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.oneOf(['desktop', 'mobile']),
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  disclaimerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ).isRequired,
};

module.exports = deviceLayout;
