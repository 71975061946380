/* eslint-disable security/detect-object-injection */
const React = require('react');
const PropTypes = require('prop-types');

const Gift = require('../icons/GiftIcon');
const Calendar = require('../icons/Calendar');
const Help = require('../icons/Help');

const icons = {
  gift: Gift,
  calendar: Calendar,
  help: Help,
};

const Icon = ({ id, attr, onClick, className, down, color }) => (
  icons[id]
    ? React.createElement(icons[id], { className: `ui-icon ${className || ''}`, onClick, down, color, ...attr })
    : null
);

Icon.propTypes = {
  id: PropTypes.string.isRequired,
  attr: PropTypes.shape({
    type: PropTypes.string,
    width: PropTypes.number,
    height: PropTypes.number,
    viewBox: PropTypes.string,
    color: PropTypes.string,
  }),
};

Icon.defaultProps = {
  attr: {},
};

module.exports = Icon;
