/* eslint-disable camelcase */
const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');

/** Local dependecies */
const AmountFormat = require('../AmountFormat');

const {
  getDebitMethodLabel,
} = require('../../helpers/subscriptionDateHelper');
const { RecurringInfoDataModel } = require('../../model/preapprovalAPIResponseDataModel');

const FixAmount = ({ i18n, recurringInfo, userData, planName }) => {
  const {
    frequency,
    frequencyType,
    repetitions,
    currencyData,
    transactionAmount,
  } = recurringInfo;

  const debitMethodLabel = frequency && frequencyType ? getDebitMethodLabel({
    frequency,
    frequencyType,
    repetitions,
    i18n,
  }) : '';

  return (
    <div className="fix-amount-view-component">
      <div className="greet">{i18n.gettext(
        'Hola{0}, tu suscripción para {1} será de:',
        userData ? `, ${userData.firstName}` : '',
        planName,
      )}
      </div>
      <div className="amount-container">
        <AmountFormat
          currencyFormat={currencyData}
          amount={transactionAmount}
        />
      </div>
      <div className="info-texts">
        <p className="important-text">
          {debitMethodLabel}
        </p>
      </div>
    </div>
  );
};

FixAmount.propTypes = {
  recurringInfo: RecurringInfoDataModel.isRequired,
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  userData: PropTypes.shape({
    firstName: PropTypes.string,
  }),
  planName: PropTypes.string.isRequired,
  hasFreeTrial: PropTypes.bool,
};

FixAmount.defaultProps = {
  userData: null,
  hasFreeTrial: false,
};

/**
 * Expose Something with i18n injection
 */
module.exports = injectI18n(FixAmount);
