/* eslint-disable max-len */
const React = require('react');
const PropTypes = require('prop-types');

const IconMoreOptions = ({ color }) => (
  <div className="icon-wrapper icon-more-options">
    <svg width="20" height="20" viewBox="0 0 20 20">
      <path fill={color} fillRule="evenodd" d="M9.994 13.6c.662 0 1.2.537 1.2 1.2 0 .662-.538 1.2-1.2 1.2-.663 0-1.2-.538-1.2-1.2 0-.663.537-1.2 1.2-1.2zm0-4.8c.662 0 1.2.537 1.2 1.2 0 .663-.538 1.2-1.2 1.2-.663 0-1.2-.537-1.2-1.2 0-.663.537-1.2 1.2-1.2zm0-4.8c.662 0 1.2.538 1.2 1.2 0 .663-.538 1.2-1.2 1.2-.663 0-1.2-.537-1.2-1.2 0-.662.537-1.2 1.2-1.2z" />
    </svg>
  </div>
);

IconMoreOptions.defaultProps = {
  color: '#009EE3',
};

IconMoreOptions.propTypes = {
  color: PropTypes.string,
};

module.exports = IconMoreOptions;
