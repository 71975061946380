/* eslint-disable max-len */
/* eslint-disable security/detect-object-injection */
const dayjs = require('dayjs');
require('dayjs/locale/es'); // load on demand
require('dayjs/locale/pt'); // load on demand
require('dayjs/locale/en'); // load on demand

const calculateNextDebit = (frequencyData, locale) => {
  let now = dayjs().locale(locale.split('_')[0]);
  if (frequencyData) {
    now = now.add(frequencyData.frequency, frequencyData.frequencyType.slice(0, -1));
  }
  return now.format('DD [de] MMMM [del] YYYY').toLowerCase();
};

const getFrequencyText = ({ frequency, frequencyType, i18n }) => {
  switch (`${frequency} ${frequencyType}`) {
    case '7 days':
      return i18n.gettext('semanal');
    case '15 days':
      return i18n.gettext('quincenal');
    case '1 months':
      return i18n.gettext('mensual');
    case '2 months':
      return i18n.gettext('bimestral');
    case '3 months':
      return i18n.gettext('trimestral');
    case '4 months':
      return i18n.gettext('cuatrimestral');
    case '6 months':
      return i18n.gettext('semestral');
    case '12 months':
      return i18n.gettext('anual');
    default:
      return '';
  }
};

const normalizeFrequencyType = (frequencyType, frequency, repetitions, i18n) => {
  const cicle = getFrequencyText({ frequency, frequencyType, i18n });
  switch (cicle) {
    case i18n.gettext('semanal'): return i18n.ngettext(i18n.gettext('semana'), i18n.gettext('semanas'), repetitions);
    case i18n.gettext('quincenal'): return i18n.ngettext(i18n.gettext('quincena'), i18n.gettext('quincenas'), repetitions);
    case i18n.gettext('mensual'): return i18n.ngettext(i18n.gettext('mes'), i18n.gettext('meses'), repetitions);
    case i18n.gettext('bimestral'): return i18n.ngettext(i18n.gettext('bimestre'), i18n.gettext('bimestres'), repetitions);
    case i18n.gettext('trimestral'): return i18n.ngettext(i18n.gettext('trimestre'), i18n.gettext('trimestres'), repetitions);
    case i18n.gettext('cuatrimestral'): return i18n.ngettext(i18n.gettext('cuatrimestre'), i18n.gettext('cuatrimestres'), repetitions);
    case i18n.gettext('semestral'): return i18n.ngettext(i18n.gettext('semestre'), i18n.gettext('semestres'), repetitions);
    case i18n.gettext('anual'): return i18n.ngettext(i18n.gettext('año'), i18n.gettext('años'), repetitions);
    default: return frequencyType;
  }
};

const getRepetitionsText = (
  i18n,
  repetitions,
  frequencyType,
  frequency,
) => i18n.jsx.ngettext(',{0} durante {1} {2}', ',{0} durante {1} {2}', {
  wrapper: 'span',
  tags: {
    0: '<br>',
  },
  replacements: {
    1: repetitions,
    2: normalizeFrequencyType(frequencyType, frequency, repetitions, i18n),
  },
  quantity: repetitions,
});

const getDebitMethodLabelFirstSentence = ({ frequency, frequencyType, repetitions, i18n }) => {
  const frequencyText = getFrequencyText({ frequency, frequencyType, i18n });
  return frequencyText
    ? i18n.gettext('Se debitará de forma {0}', frequencyText)
    : i18n.gettext(
      'Se cargará cada {0} {1}',
      frequency,
      normalizeFrequencyType(frequencyType, frequency, repetitions, i18n),
    );
};

const getDebitMethodLabel = ({
  frequency, frequencyType, repetitions, i18n,
}) => (
  [
    getDebitMethodLabelFirstSentence({
      frequency,
      frequencyType,
      repetitions,
      i18n,
    }),
    repetitions
      ? getRepetitionsText(i18n, repetitions, frequencyType, frequency)
      : '',
  ]
);

const getSubscriptionEndLabel = ({ frequency, frequencyType, repetitions = 1, i18n, locale }) => i18n.gettext(
  'La suscripción finalizará el {0}',
  calculateNextDebit({ frequency: frequency * repetitions, frequencyType }, locale),
);

module.exports = {
  getDebitMethodLabel,
  getSubscriptionEndLabel,
  normalizeFrequencyType,
};
