/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const MoneyAmount = require('@andes/money-amount');

/**
 * amountFormat
 */
const AmountFormat = ({ currencyFormat, amount, size }) => {
  const integerPart = Math.trunc(amount).toLocaleString();
  const formatCents = (intAmount) => {
    const cents = intAmount.toFixed(2).split('.')[1];
    return cents > 0 ? cents : '';
  };

  const amountFormat = {
    fraction: integerPart.replace(/[,.]/g, currencyFormat.thousandsSeparator),
    cents: formatCents(amount),
  };

  return (<MoneyAmount
    className="money-amount"
    size={size}
    value={{
      fraction: amountFormat.fraction,
      cents: amountFormat.cents,
    }}
    symbol={currencyFormat.symbol}
    currencyId={currencyFormat.id}
  />);
};

/**
 * View propTypes
 */
AmountFormat.defaultProps = {
  currencyFormat: {
    id: '',
    decimalPlaces: 2,
    symbol: '$',
    decimalSeparator: ',',
    thousandsSeparator: '.',
    localeCode: '',
  },
  amount: null,
  size: 36,
};

AmountFormat.propTypes = {
  currencyFormat: PropTypes.shape({
    id: PropTypes.string.isRequired,
    symbol: PropTypes.string.isRequired,
    decimalPlaces: PropTypes.number.isRequired,
    thousandsSeparator: PropTypes.string.isRequired,
    decimalSeparator: PropTypes.string.isRequired,
  }),
  amount: PropTypes.number,
  size: PropTypes.number,
};

module.exports = AmountFormat;
