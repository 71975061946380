/* eslint-disable security/detect-non-literal-regexp */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
const React = require('react');
const PropTypes = require('prop-types');
const { MaskNumericField } = require('@andes/masktextfield');
const { money } = require('@andes/masktextfield/build/masks/ar');
const { Button } = require('@andes/button');
const injectI18n = require('nordic/i18n/injectI18n');
const { CurrencyDataModel } = require('../../model/preapprovalAPIResponseDataModel');
const { meliGA } = require('../../helpers/analyticsHelper');

const { useState } = React;

const CustomAmountComponent = ({ i18n, suggestions, currency, onAmountChange, message }) => {
  const [selectedPrice, setSelectedPrice] = useState('0');
  const onBlurFn = (event) => {
    if (!event.target.value) {
      setSelectedPrice('0');
      onAmountChange('');
    }
    meliGA.send('event', 'SUBSCRIPTION', 'INPUT_AMOUNT');
  };
  const onChangeFn = (event) => {
    setSelectedPrice(event.target.value || '');
    const amount = (event.target.value).replace(currency.symbol, '')
      .replace(new RegExp(`\\${currency.thousandsSeparator}`, 'g'), '')
      .replace(currency.decimalSeparator, '.');

    onAmountChange(amount);
  };
  const onFocusFn = () => setSelectedPrice('');
  const onMoneyOptionClick = (price) => {
    setSelectedPrice(`${price}`);
    onAmountChange(price);
    meliGA.send('event', 'SUBSCRIPTION', 'SELECT_AMOUNT');
  };

  const { mask } = money({
    prefix: currency.symbol,
    decimalSymbol: currency.decimalSeparator,
    thousandsSeparatorSymbol: currency.thousandsSeparator,
  });

  return (
    <div className="custom-amount-component">
      <div className="shrink-container">
        <div className="money-container">
          <MaskNumericField
            mask={mask}
            guide={false}
            centered
            maxLength="11"
            value={selectedPrice}
            onChange={onChangeFn}
            onFocus={onFocusFn}
            onBlur={onBlurFn}
            modifier={message.type}
            message={message.text}
          />
          <div className="label">{i18n.gettext('Monto')}:</div>
        </div>
        {suggestions.length ? (
          <div className="money-options">
            {suggestions.map((price) => (
              <div
                key={price}
                className="money-option"
              >
                <Button
                  hierarchy="transparent"
                  size="medium"
                  fullWidth
                  onClick={() => onMoneyOptionClick(price)}
                >
                  {currency.symbol}{price}
                </Button>
              </div>
            ))}
          </div>
        ) : ''}
      </div>
    </div>
  );
};

CustomAmountComponent.propTypes = {
  suggestions: PropTypes.arrayOf(PropTypes.number),
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  currency: CurrencyDataModel.isRequired,
  onAmountChange: PropTypes.func,
  message: PropTypes.objectOf(PropTypes.number),
};

CustomAmountComponent.defaultProps = {
  suggestions: [],
  onAmountChange: () => false,
  message: {
    type: '',
    text: '',
  },
};

/**
 * Expose component with i18n injection
 */
module.exports = injectI18n(CustomAmountComponent);
