const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const { Checkbox } = require('@andes/checkbox');

const CheckboxTYC = ({ i18n, disclaimerLinks = [], onChange, isAccepted }) => {
  const tyc = disclaimerLinks.find(({ id }) => id === 'tyc');
  const privacy = disclaimerLinks.find(({ id }) => id === 'privacy');
  const termsAndPrivacyText = i18n.jsx.gettext('Acepto los {0}Términos y condiciones{1} y autorizo el uso de mis datos de acuerdo a la {2}Declaración de Privacidad{3}.', {
    tags: {
      0: `<a href="${tyc.link}" target="_blank">`,
      1: '</a>',
      2: `<a href="${privacy.link}" target="_blank">`,
      3: '<a/>',
    },
  });

  return (
    <div className="checkbox-tyc">
      <Checkbox
        label={termsAndPrivacyText}
        onChange={() => {
          onChange(!isAccepted);
        }}
        checked={isAccepted}
        className="verify-checkbox"
      />
    </div>
  );
};

CheckboxTYC.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  disclaimerLinks: PropTypes.arrayOf({
    name: PropTypes.string,
    link: PropTypes.string,
  }),
  isAccepted: PropTypes.bool,
};

CheckboxTYC.defaultProps = {
  i18n: {
    gettext: t => t,
  },
  disclaimerLinks: [],
  isAccepted: false,
};

module.exports = injectI18n(CheckboxTYC);
