/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const CustomAmountComponent = require('../CustomAmountComponent');
const { getDebitMethodLabel } = require('../../helpers/subscriptionDateHelper');
const { RecurringInfoDataModel } = require('../../model/preapprovalAPIResponseDataModel');

const CustomAmountViewComponent = ({
  i18n,
  recurringInfo,
  userData,
  planName,
  onAmountChange,
  message,
  amountSuggestions }) => {
  const {
    frequency, frequencyType,
    repetitions, currencyData,
  } = recurringInfo;

  const debitMethodLabel = frequency && frequencyType ? getDebitMethodLabel({
    frequency,
    frequencyType,
    repetitions,
    i18n,
  }) : '';

  return (
    <div className="custom-amount-view-component">
      <div className="greet">
        {i18n.gettext('Hola')}{userData ? `, ${userData.firstName}, ` : ', '}
        {i18n.gettext('elige el monto de tu suscripción para {0}', planName)}
      </div>
      <CustomAmountComponent
        currency={currencyData}
        suggestions={amountSuggestions}
        onAmountChange={onAmountChange}
        message={message}
      />
      <div className="info-texts">
        <p className="important-text">{debitMethodLabel}</p>
      </div>
    </div>
  );
};

CustomAmountViewComponent.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  recurringInfo: RecurringInfoDataModel.isRequired,
  userData: PropTypes.shape({
    firstName: PropTypes.string,
  }),
  planName: PropTypes.string.isRequired,
  hasFreeTrial: PropTypes.boolean,
  onAmountChange: PropTypes.func.isRequired,
  message: PropTypes.objectOf(PropTypes.number),
};

CustomAmountViewComponent.defaultProps = {
  userData: null,
  hasFreeTrial: false,
  message: {
    type: '',
    text: '',
  },
};

/**
 * Expose Something with i18n injection
 */
module.exports = injectI18n(CustomAmountViewComponent);
