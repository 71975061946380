/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');

/**
 * Help
 */
const Help = ({ className, viewBox, width, height, onClick, color }) => (
  <svg className={className} xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox={viewBox} onClick={onClick}>
    <path fill={color} fillRule="nonzero" d="M8 .2a7.8 7.8 0 1 1 0 15.6A7.8 7.8 0 0 1 8 .2zm0 1.2a6.6 6.6 0 1 0 0 13.2A6.6 6.6 0 0 0 8 1.4zm.018 9.252c.494 0 .91.416.91.91 0 .494-.416.91-.91.91a.921.921 0 0 1-.91-.91c0-.494.417-.91.91-.91zm.079-7.124c1.638 0 2.639.871 2.639 2.041 0 1.079-.729 1.599-1.379 2.054-.493.364-.948.676-.948 1.196 0 .208.116.442.285.585l-1.144.377a1.484 1.484 0 0 1-.468-1.105c0-.871.625-1.313 1.183-1.716.481-.338.91-.637.91-1.144 0-.507-.39-.936-1.235-.936-.793 0-1.365.364-1.807.91l-.87-.975c.663-.806 1.663-1.287 2.834-1.287z" />
  </svg>

);

Help.propTypes = {
  className: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  onClick: PropTypes.func,
  color: PropTypes.string,
};

Help.defaultProps = {
  className: '',
  width: 16,
  height: 16,
  viewBox: '0 0 16 16',
  onClick: null,
  color: '#009EE3',
};

module.exports = Help;
