const PropTypes = require('prop-types');

const CurrencyDataModel = PropTypes.shape({
  id: PropTypes.string,
  symbol: PropTypes.string,
  decimalPlaces: PropTypes.number,
  decimalSeparator: PropTypes.number,
  thousandsSeparator: PropTypes.number,
});

const FrequencyObjDataModel = PropTypes.shape({
  frequency: PropTypes.number,
  frequencyType: PropTypes.string,
});

const RecurringInfoDataModel = PropTypes.shape({
  frequency: PropTypes.number,
  frequencyType: PropTypes.string,
  transactionAmount: PropTypes.number,
  repetitions: PropTypes.number,
  currencyData: CurrencyDataModel,
  hasFreeTrial: FrequencyObjDataModel,
});

module.exports = {
  CurrencyDataModel,
  FrequencyObjDataModel,
  RecurringInfoDataModel,
};
