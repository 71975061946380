/* eslint-disable react/forbid-prop-types */
const React = require('react');
const PropTypes = require('prop-types');

const mobileLayout = ({ children }) => (
  <div className="mobile-layout">
    {children}
  </div>
);

mobileLayout.propTypes = {
  children: PropTypes.any,
};

mobileLayout.defaultProps = {
  children: null,
};

module.exports = mobileLayout;
