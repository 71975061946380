/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */

const React = require('react');
const PropTypes = require('prop-types');
const { Button } = require('@andes/button');
const { Text } = require('@andes/typography');
const injectI18n = require('nordic/i18n/injectI18n');

const IconMercadoPagoFull = require('../icons/MercadoPagoFull');
const UserInfo = require('../UserInfo');
const GroupMenu = require('../GroupMenu');

const UserHeader = ({ siteId, userData, i18n }) => {
  const getCurrentURL = () => encodeURI(window.location.href);
  const signOut = () => {
    const signOutURL = `https://www.mercadolibre.com/jms/${siteId.toLowerCase()}/lgz/logout?go=${getCurrentURL()}`;
    window.location.href = signOutURL;
  };
  const signIn = () => {
    const signInURL = `https://www.mercadolibre.com/jms/${siteId.toLowerCase()}/lgz/login?platform_id=MP&go=${getCurrentURL()}&loginType=explicit`;
    window.location.href = signInURL;
  };
  return (
    <div className="user-header navbar">
      <div className="user-header__content">
        <IconMercadoPagoFull />
        {userData ? (
          <>
            <UserInfo
              userName={`${userData.firstName} ${userData.lastName}`}
              imageURL={userData.thumbnailUrl}
            />
            <GroupMenu>
              <div className="info-row">
                <span className="info-row__title">
                  {i18n.gettext('Ingresaste como:')}
                </span>
                <span className="info-row__detail">{userData.email}</span>
              </div>
              <div className="button-step">
                <Text
                  color="link"
                  href="/subscriptions/list"
                  component="a"
                  target="_blank"
                >
                  {i18n.gettext('Ir a mis débitos')}
                </Text>
              </div>
              <div className="button-step">
                <Text
                  onClick={() => signOut()}
                  color="link"
                  component="a"
                  className="user-logout"
                >
                  {i18n.gettext('Cerrar sesión')}
                </Text>
              </div>
            </GroupMenu>
          </>
        ) : (
          <Button
            onClick={() => signIn()}
            hierarchy="transparent"
            className="option-login"
            size="large"
          >
            {i18n.gettext('Ingresá')}
          </Button>
        )}
      </div>
    </div>
  );
};

UserHeader.propTypes = {
  siteId: PropTypes.string.isRequired,
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    email: PropTypes.string,
    lastName: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  }),
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
};

UserHeader.defaultProps = {
  userData: null,
};

module.exports = injectI18n(UserHeader);
