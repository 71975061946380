const React = require('react');
const PropTypes = require('prop-types');
const Avatar = require('@panel/avatar');
const classNames = require('classnames');
const { Image } = require('nordic/image');

const Brand = ({ brandName, brandLogo, maxBrandnameLength, maxInitialsLength }) => {
  if (!brandName) {
    return null;
  }

  const initials = brandName.split(' ')
    .map(currValue => currValue[0])
    .join('')
    .substring(0, maxInitialsLength)
    .toUpperCase();

  const brandNameStyle = classNames(
    'brand__name',
    `brand__name--${brandName.length > maxBrandnameLength ? 'large' : 'small'}`,
  );

  return (
    <div className="brand">
      {
        brandLogo ? (
          <span className="brand__img">
            <Image src={brandLogo} alt={brandName} title={brandName} />
          </span>
        ) : (
          <Avatar initials={initials} />
        )
      }
      <span className={brandNameStyle}>{brandName}</span>

    </div>
  );
};

Brand.propTypes = {
  brandName: PropTypes.string,
  brandLogo: PropTypes.string,
  maxBrandnameLength: PropTypes.number,
  maxInitialsLength: PropTypes.number,
};

Brand.defaultProps = {
  brandName: '',
  brandLogo: '',
  maxBrandnameLength: 17,
  maxInitialsLength: 3,
};

module.exports = Brand;
