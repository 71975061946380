const send = (hitType, eventCategory, eventAction, eventLabel = null, hitCallback = null) => {
  const gaProps = {
    hitType,
    eventCategory,
    eventAction,
  };

  if (eventLabel) {
    Object.assign(gaProps, {
      eventLabel,
    });
  }

  if (hitCallback) {
    Object.assign(gaProps, {
      hitCallback,
    });
  }

  window.meli_ga('send', gaProps);
};

const customDimensions = (req) => ({
  isGuestUser: !(req.auth && req.auth.user.rootId),
  context: req.query?.flow?.toUpperCase() || 'DEFAULT',
});

module.exports = {
  meliGA: { send },
  customDimensions,
};
