const React = require('react');
const PropTypes = require('prop-types');

const Calendar = ({ className, viewBox, width, height, color }) => (
  <svg className={className} width={width} height={height} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
    <path
      fill={color}
      fillRule="nonzero"
      d="M6.36 2.195l-.001 1.199h7.201V2.195h1.2v1.199l3.04.001v14.4H2.2v-14.4l2.959-.001V2.195h1.2zm10.239
      5.999h-13.2l.001 8.401h13.2l-.001-8.401zm-11.44-3.6L3.4 4.595l-.001
      2.399h13.2l.001-2.399-1.84-.001v1.201h-1.2V4.594H6.359v1.201h-1.2V4.594z"
    />
  </svg>
);

Calendar.propTypes = {
  className: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

Calendar.defaultProps = {
  color: '#009EE3',
  className: '',
  width: 20,
  height: 24,
  viewBox: '0 0 20 16',
};

module.exports = Calendar;
