/* eslint-disable react/forbid-prop-types */
/* eslint-disable react/prefer-stateless-function */
/* eslint-disable no-useless-constructor */
/**
 * Module dependencies
 */
const React = require('react');
const PropTypes = require('prop-types');
const Head = require('nordic/head');
const Script = require('nordic/script');
const Page = require('nordic/page');
const injectI18n = require('nordic/i18n/injectI18n');
const ActionManagerWrapper = require('./ActionManagerWrapper');
const RenderJs = require('../../helpers/RenderJs');
const { isIframe } = require('../../helpers/Dom');

/**
 * InitPoint View Component
 */
class View extends React.Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    if (isIframe()) {
      RenderJs.start();
    }
  }

  render() {
    const {
      i18n,
      translations,
      preapproval,
      collector,
      locale,
      device,
      analytics,
      userData,
      amountLimits,
      amountSuggestions,
      siteId,
      platform,
      browser,
      disclaimerLinks,
      restclientContext,
      twitterAccount,
      isGuest,
      invitationPayerId,

    } = this.props;
    const preloadedState = {
      i18n,
      translations,
      preapproval,
      collector,
      locale,
      device,
      analytics,
      userData,
      amountLimits,
      amountSuggestions,
      siteId,
      platform,
      browser,
      disclaimerLinks,
      restclientContext,
      twitterAccount,
      isGuest,
      invitationPayerId,
    };
    return (
      <Page
        name="initPoint"
        className="init-point"
        state={preloadedState}
        analytics={analytics}
        deviceType={device}
      >
        <Head>
          <title>{i18n.gettext('Suscripciones')}</title>
          <meta property="og:site_name" content="Mercado Pago" />
          <meta property="og:title" content={preapproval.reason} />
          <meta property="og:description" content={i18n.gettext('Pago seguro por Mercado Pago')} />
          <meta property="og:type" content="article" />
          <meta property="og:image" content={collector.thumbnailUrl} />
          <meta name="twitter:card" content="summary" />
          <meta name="twitter:site" content={twitterAccount} />
          <meta name="twitter:title" content={preapproval.reason} />
          <meta name="twitter:description" content={i18n.gettext('Pago seguro por Mercado Pago')} />
          <meta name="twitter:creator" content={twitterAccount} />
          <meta name="twitter:image" content={collector.thumbnailUrl} />
        </Head>
        <Script src="vendor.js" />
        <ActionManagerWrapper
          device={device}
          collector={collector}
          userData={userData}
          preapproval={preapproval}
          locale={locale}
          i18n={i18n}
          amountLimits={amountLimits}
          amountSuggestions={amountSuggestions}
          siteId={siteId}
          disclaimerLinks={disclaimerLinks}
          restclientContext={restclientContext}
          isGuest={isGuest}
          invitationPayerId={invitationPayerId}
        />
      </Page>
    );
  }
}

View.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  translations: PropTypes.shape({}),
  preapproval: PropTypes.object.isRequired,
  analytics: PropTypes.object.isRequired,
  collector: PropTypes.shape({
    brandName: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    email: PropTypes.string,
  }),
  amountLimits: PropTypes.shape({
    maxAmount: PropTypes.number,
    minAmount: PropTypes.number,
  }),
  siteId: PropTypes.string.isRequired,
  disclaimerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  twitterAccount: PropTypes.string,
  isGuest: PropTypes.any,
};

View.defaultProps = {
  translations: {},
  userData: null,
  amountLimits: {},
  disclaimerLinks: [],
  twitterAccount: '',
  isGuest: null,
};

/**
 * Inject i18n context as props into View.
 */
module.exports = injectI18n(View);
