const React = require('react');
const PropTypes = require('prop-types');
const Avatar = require('@panel/avatar');

const UserAvatar = ({ image }) => (
  <div className="user-avatar"><Avatar image={image} /></div>
);

UserAvatar.propTypes = {
  image: PropTypes.string,
};

UserAvatar.defaultProps = {
  image: '', // TODO: get default image URL
};

module.exports = UserAvatar;
