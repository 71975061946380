/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* global document */
const React = require('react');
const PropTypes = require('prop-types');
const MoreOptionsIcon = require('../icons/MoreOptions');

class GroupMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showPopup: false,
    };

    this.setMenuRef = this.setMenuRef.bind(this);
    this.handleClick = this.handleClick.bind(this);
    this.handleClickOutside = this.handleClickOutside.bind(this);
  }


  handleClick() {
    const { showPopup } = this.state;
    if (!showPopup) {
      document.addEventListener('click', this.handleClickOutside);
    }
    this.setState({ showPopup: !showPopup });
  }


  handleClickOutside(event) {
    if (this.menuRef && !this.menuRef.contains(event.target)) {
      this.setState({
        showPopup: false,
      }, () => document.removeEventListener('click', this.handleClickOutside));
    }
  }


  setMenuRef(node) {
    this.menuRef = node;
  }

  render() {
    const { showPopup } = this.state;
    const { children } = this.props;

    return (
      <div className="group-menu" ref={this.setMenuRef}>
        <div type="button" className="group-menu__button" onClick={this.handleClick} data-testid="test-group-menu">
          <MoreOptionsIcon />
        </div>
        {showPopup
          && (
            <div className="group-menu__popup">
              {
                children && children.map(option => (
                  <div className="group-menu__popup-option">
                    {option}
                  </div>
                ))
              }
            </div>
          )}
      </div>
    );
  }
}

GroupMenu.defaultValues = {
  children: null,
};

GroupMenu.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
};

module.exports = GroupMenu;
