const React = require('react');
const PropTypes = require('prop-types');
const Brand = require('../Brand');

const HeaderComponent = ({ brandName, brandLogo }) => (
  <div className="brand-header">
    <Brand
      brandName={brandName}
      brandLogo={brandLogo}
    />
  </div>
);

HeaderComponent.propTypes = {
  brandName: PropTypes.string,
  brandLogo: PropTypes.string,
};

HeaderComponent.defaultProps = {
  brandName: '',
  brandLogo: '',
};

module.exports = HeaderComponent;
