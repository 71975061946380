const React = require('react');
const PropTypes = require('prop-types');

const GiftIcon = ({ className, viewBox, width, height, color }) => (
  <svg className={className} width={width} height={height} viewBox={viewBox} xmlns="http://www.w3.org/2000/svg">
    <g fill="none" fillRule="evenodd">
      <path
        fill={color}
        fillOpacity=".8"
        fillRule="nonzero"
        d="M14.2643 3.05c1.6371 0 2.9643 1.3272 2.9643 2.9643a2.9507 2.9507 0
        01-.3862 1.464l2.9326.0003V20.382H4.325V7.4786l2.9326-.0002a2.9507
        2.9507 0 01-.3862-1.4641c0-1.6371 1.3272-2.9643 2.9643-2.9643.8808 0
        1.6719.3842 2.2148.994.5419-.6098 1.333-.994 2.2138-.994zM11.281 14.521H5.824l.001
        4.3611 5.456-.0001v-4.361zm6.993 0h-5.493v4.361l5.494.0001-.001-4.3611zm-6.993-5.543l-5.456.0006-.001
        4.0424h5.457V8.978zm6.994.0006l-5.494-.0006v4.043h5.493l.001-4.0424zM9.8357 4.55c-.8087
        0-1.4643.6556-1.4643 1.4643s.6556 1.4643 1.4643
        1.4643l1.4633-.0006.001-1.4758v.012c0-.8086-.6556-1.4642-1.4643-1.4642zm4.4286
        0c-.8087 0-1.4643.6556-1.4643 1.4643v-.0121V7.478l1.4643.0006c.7611 0
        1.3866-.5808 1.4576-1.3233l.0067-.141c0-.8087-.6556-1.4643-1.4643-1.4643z"
      />
    </g>
  </svg>
);

GiftIcon.propTypes = {
  className: PropTypes.string.isRequired,
  viewBox: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
};

GiftIcon.defaultProps = {
  color: '#00a650',
  className: '',
  width: 20,
  height: 20,
  viewBox: '0 0 20 20',
};

module.exports = GiftIcon;
