const React = require('react');
const PropTypes = require('prop-types');
const injectI18n = require('nordic/i18n/injectI18n');
const Snackbar = require('@andes/snackbar');
const restclient = require('nordic/restclient')();

/*
* Andes dependecies
*/
const Spinner = require('@andes/spinner');

/*
* Local dependecies
*/
const FooterComponent = require('../../../components/FooterComponent');
const FixAmount = require('../../../components/FixAmount');
const CustomAmountViewComponent = require('../../../components/CustomAmountViewComponent');
const HeaderComponent = require('../../../components/HeaderComponent');
const DeviceLayout = require('../deviceLayouts/deviceLayout');
const UserHeader = require('../../../components/UserHeader');
const CheckboxTYC = require('../../../components/CheckboxTYC');
const TagSpecialMessage = require('../../../components/TagSpecialMessage');
const IconSelector = require('../../../components/IconSelector');
const ModalBillingExplanation = require('../../../components/ModalBillingExplanation');
const { normalizeFrequencyType } = require('../../../helpers/subscriptionDateHelper');

class ActionManagerWrapper extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      errorMessage: '',
      customAmount: '',
      snackbar: null,
      acceptedTerms: false,
      showBillingModal: false,
    };

    this.createPreference = this.createPreference.bind(this);
    this.loading = this.loading.bind(this);
    this.onAmountChange = this.onAmountChange.bind(this);
    this.onTermsChange = this.onTermsChange.bind(this);
    this.showModalBillingExplanation = this.showModalBillingExplanation.bind(this);
    this.hideModalBillingExplanation = this.hideModalBillingExplanation.bind(this);
  }

  onAmountChange(customAmount) {
    this.setState({
      customAmount,
      errorMessage: '',
    });
  }

  onTermsChange(newVal) {
    this.setState({
      acceptedTerms: newVal,
    });
  }

  getParams() {
    const {
      preapproval: {
        id,
        type,
        autoRecurring: {
          transactionAmount,
        },
      },
      collector: {
        collectorId,
      },
      invitationPayerId,
    } = this.props;

    const {
      customAmount,
    } = this.state;

    const params = {
      preapprovalId: id,
      type,
      amount: transactionAmount || customAmount,
      collectorId,
      invitationPayerId,
    };

    return params;
  }

  loading(status) {
    this.setState({
      loading: status,
    });
  }

  addSnackbarMessage(message, type) {
    this.setState({
      snackbar: null,
    }, () => {
      this.setState({
        snackbar: {
          type,
          message,
          delay: 3000,
          show: true,
        },
      });
    });
  }

  showError(message) {
    this.setState({
      errorMessage: '',
    }, () => {
      this.setState({
        errorMessage: message,
      });
    });
  }

  redirectToPreferenceChekout(url) {
    window.location.assign(url);
  }

  amountLimitValidation(amount) {
    const {
      amountLimits: {
        maxAmount,
        minAmount,
      },
      i18n,
    } = this.props;

    const amountProperties = {
      status: false,
      message: {
        type: 'error',
        text: '',
      },
    };

    if (amount >= minAmount && amount <= maxAmount) {
      amountProperties.status = true;
      amountProperties.message.type = '';
      amountProperties.message.text = '';
      return amountProperties;
    }

    if (amount <= minAmount) {
      amountProperties.message.text = i18n.gettext('El monto debe ser mayor al que ingresaste.');
    } else {
      amountProperties.message.text = i18n.gettext('El monto debe ser menor al que ingresaste.');
    }

    this.showError(amountProperties.message);
    return amountProperties;
  }

  createPreference() {
    const {
      i18n,
      restclientContext,
    } = this.props;
    const params = this.getParams();
    const isAmountOk = this.amountLimitValidation(Number(params.amount));
    if (params.preapprovalId !== '' && isAmountOk.status) {
      this.loading(true);
      restclient
        .post('/preferences', { params, headers: restclientContext })
        .then((res) => {
          this.redirectToPreferenceChekout(res.data.init_point);
        }).catch(() => {
          this.loading(false);
          this.addSnackbarMessage(
            `${i18n.gettext('Algo salió mal')}. ${i18n.gettext('Por favor, vuelve a intentarlo en unos minutos')}.`,
            'error',
          );
        });
    }
  }

  showModalBillingExplanation() {
    this.setState({
      showBillingModal: true,
    });
  }

  hideModalBillingExplanation() {
    this.setState({
      showBillingModal: false,
    });
  }

  render() {
    const {
      i18n,
      device,
      collector,
      userData,
      preapproval,
      locale,
      siteId,
      amountSuggestions,
      disclaimerLinks,
      isGuest,
      amountLimits,
    } = this.props;

    const {
      fixedAmount,
      autoRecurring: {
        transactionAmount,
      },
    } = preapproval;

    const {
      loading,
      errorMessage,
      snackbar,
      acceptedTerms,
      showBillingModal,
      customAmount,
    } = this.state;

    const filteredDisclaimerLinks = isGuest ? disclaimerLinks.filter((dl = {}) => !['tyc', 'privacy'].includes(dl.id || '')) : disclaimerLinks;
    const { billingDay, frequencyType, frequency } = preapproval.autoRecurring;
    const frequencyTrial = normalizeFrequencyType(frequencyType, frequency, 1, i18n);

    const freeTrialRender = () => (preapproval.hasFreeTrial.first_invoice_offset
      ? (
        <TagSpecialMessage
          label={i18n.gettext('{0} días gratis', preapproval.hasFreeTrial.first_invoice_offset)}
          icon="gift"
        />
      ) : (
        <TagSpecialMessage
          label={i18n.gettext('1 {0} gratis', frequencyTrial)}
          icon="gift"
        />
      ));

    return (
      <>
        <UserHeader siteId={siteId} userData={userData} />
        <DeviceLayout device={device} i18n={i18n} disclaimerLinks={filteredDisclaimerLinks}>
          <HeaderComponent brandLogo={collector.thumbnailUrl} brandName={collector.brandName} userData={userData} />
          <div className="body-container">
            {fixedAmount
              ? (
                <FixAmount
                  recurringInfo={preapproval.autoRecurring}
                  locale={locale}
                  userData={userData}
                  planName={preapproval.reason}
                />
              )
              : (
                <CustomAmountViewComponent
                  recurringInfo={preapproval.autoRecurring}
                  locale={locale}
                  userData={userData}
                  planName={preapproval.reason}
                  onAmountChange={this.onAmountChange}
                  message={errorMessage}
                  amountSuggestions={amountSuggestions}
                />
              )}
            <div className="tags-container">
              {preapproval.hasFreeTrial && freeTrialRender()}
              {billingDay && (
                <TagSpecialMessage
                  label={i18n.gettext('Pagarás cada día {0}', billingDay)}
                  icon="calendar"
                  borderColor="lightBlue"
                />
              )}
            </div>
            {billingDay && (
              <div
                className={`billing-explanation-trigger ${!(transactionAmount || customAmount) && 'billing-explanation-trigger-disabled'}`}
                onClick={(transactionAmount || customAmount) && this.showModalBillingExplanation}
                role="button"
                tabIndex="0"
              >
                <IconSelector
                  id="help"
                />
                <span>
                  {i18n.gettext('Entiende tus próximos pagos')}
                </span>
              </div>
            )}
            {preapproval.hasFreeTrial && (
              <div className="important-text-small">
                {i18n.gettext('Para obtener los días gratis, elige pagar con tarjeta o con dinero disponible en Mercado Pago.')}
              </div>
            )}
            {isGuest && (
              <CheckboxTYC disclaimerLinks={disclaimerLinks} onChange={this.onTermsChange} isAccepted={acceptedTerms} />
            )}
            <div className="message_shared_data">
              {i18n.gettext('Tu nombre y tu e-mail se compartirán con el vendedor.')}
            </div>
          </div>
          <FooterComponent
            device={device}
            text={i18n.gettext('Elegir medio de pago')}
            onClickAction={this.createPreference}
            disclaimerLinks={filteredDisclaimerLinks}
            i18n={i18n}
            disabled={isGuest ? !acceptedTerms : false}
          />
        </DeviceLayout>
        {loading && (
          <Spinner
            modifier="fullscreen"
            size="large"
          />
        )}
        {
          snackbar && (
            <Snackbar {...snackbar} />
          )
        }
        {billingDay && (
          <ModalBillingExplanation
            onClose={this.hideModalBillingExplanation}
            visible={showBillingModal}
            recurringInfo={preapproval.autoRecurring}
            hasFreeTrial={preapproval.hasFreeTrial}
            freeTrialPeriod={preapproval.hasFreeTrial && preapproval.hasFreeTrial.first_invoice_offset}
            frequencyTrial={frequencyTrial}
            customAmount={customAmount}
            device={device}
            closeButtonLabel=""
            amountLimits={amountLimits}
          />
        )}
      </>
    );
  }
}

/**
* Checking PropTypes
*/
ActionManagerWrapper.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func,
  }),
  preapproval: PropTypes.objectOf(PropTypes.string),
  collector: PropTypes.shape({
    brandName: PropTypes.string,
    thumbnailUrl: PropTypes.string,
    collectorId: PropTypes.number,
  }).isRequired,
  locale: PropTypes.string.isRequired,
  device: PropTypes.string.isRequired,
  userData: PropTypes.shape({
    firstName: PropTypes.string,
    email: PropTypes.string,
    lastName: PropTypes.string,
    thumbnailUrl: PropTypes.string,
  }),
  amountLimits: PropTypes.objectOf.isRequired,
  siteId: PropTypes.string.isRequired,
  disclaimerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
  isGuest: PropTypes.bool,
};

/**
 * Default Props
 */
ActionManagerWrapper.defaultProps = {
  i18n: {
    gettext: t => t,
  },
  userData: null,
  preapproval: {
    id: '',
    autoRecurring: false,
    reason: '',
  },
  disclaimerLinks: [],
  isGuest: false,
};

/**
 * Export ActionManagerWrapper
 */
module.exports = injectI18n(ActionManagerWrapper);
