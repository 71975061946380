const React = require('react');
const PropTypes = require('prop-types');
const Shield = require('../icons/Shield');

const SafePaymentMessage = ({ i18n }) => (
  <div className="safe-payment-container">
    <div className="safe-payment-message">
      <Shield />
      <span>{i18n.gettext('Pago seguro por Mercado Pago')}</span>
    </div>
  </div>
);

SafePaymentMessage.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
};

/**
 * Expose Something with i18n injection
 */
module.exports = SafePaymentMessage;
