/**
 * Module dependencies
 */
const React = require('react');
const { AndesProvider } = require('@andes/context');
const { hydrate } = require('nordic/hydrate');
const I18n = require('nordic/i18n');
const I18nProvider = require('nordic/i18n/I18nProvider');
const View = require('../pages/initPoint/view');

/**
 * Get server state
 */
const preloadedState = window.__PRELOADED_STATE__ || {};

/**
 * i18n
 */
const i18n = new I18n({ translations: preloadedState.translations });

/**
 * Mount View on client
 */
hydrate(
  <I18nProvider i18n={i18n}>
    <AndesProvider locale={preloadedState.locale}>
      <View
        {...preloadedState}
      />
    </AndesProvider>
  </I18nProvider>,
  document.getElementById('root-app'),
);
