const dayjs = require('dayjs');
const duration = require('dayjs/plugin/duration');

dayjs.extend(duration);

const getDate = (date) => dayjs(date);

const getNumberOfDay = (date, optionalDate, formatType) => (
  // eslint-disable-next-line no-nested-ternary
  formatType && optionalDate
    ? dayjs(date).date(optionalDate).format(formatType)
    : !formatType && optionalDate
      ? dayjs(date).date(optionalDate)
      : dayjs(date).date()
);

const dateDuration = (date) => dayjs.duration(date);

const formatDate = (date, formatType) => dayjs(date).format(formatType);

const dateIsAfter = (date, dateAfter, period) => dayjs(date).isAfter(dateAfter, period);

const addTime = (date, timeAdd, period, formatType) => (
  formatType
    ? dayjs(date).add(timeAdd, period).format(formatType)
    : dayjs(date).add(timeAdd, period)
);
const addTimeAndSet = (date, timeAdd, period, setType, setTime) => (
  dayjs(date).add(timeAdd, period).set(setType, setTime)
);

const dateDifference = (date, diffDate, frequency, boolean) => dayjs(date).diff(diffDate, frequency, boolean);

const difference = (date, diffDate) => date.diff(diffDate);

const sameDate = (date, same) => date.isSame(same);

module.exports = {
  getDate,
  getNumberOfDay,
  dateDuration,
  formatDate,
  addTime,
  addTimeAndSet,
  dateIsAfter,
  dateDifference,
  difference,
  sameDate,
};
