const React = require('react');
const PropTypes = require('prop-types');
const { Button } = require('@andes/button');
const SafePaymentMessage = require('../SafePaymentMessage/SafePaymentMessage');
const DisclaimerLinks = require('../DisclaimerLinks');

const { useEffect, useRef, useState } = React;
const { ButtonText } = Button;

const FooterComponent = ({ device, text = 'Continuar', onClickAction, i18n, disclaimerLinks, disabled }) => {
  let component = useRef(null);
  const [isBottom, setIsBottom] = useState(false);
  const [height, setHeight] = useState(0);

  function handleScroll() {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const { body } = document;
    const html = document.documentElement;
    const docHeight = Math.max(
      body.scrollHeight,
      body.offsetHeight,
      html.clientHeight,
      html.scrollHeight,
      html.offsetHeight,
    );
    const windowBottom = windowHeight + window.pageYOffset;
    const newIsBottom = windowBottom === docHeight;

    if (isBottom !== newIsBottom) setIsBottom(newIsBottom);
  }

  useEffect(() => {
    if (device === 'mobile') {
      window.addEventListener('scroll', handleScroll);
      return () => window.removeEventListener('scroll', handleScroll);
    }
    return e => e;
  });

  const getClassName = () => {
    if (device === 'desktop') {
      return 'footer-component__desktop';
    }
    return `footer-component ${!isBottom ? 'with-background' : ''}`;
  };

  const setRef = (elem) => {
    if (elem) {
      component = elem;
      setHeight(component.clientHeight);
      if (device === 'mobile') {
        handleScroll();
      }
    }
  };

  return (
    <>
      <div className={getClassName()} ref={setRef}>
        <Button hierarchy="loud" size="large" fullWidth onClick={onClickAction} disabled={disabled}>
          <ButtonText>{text}</ButtonText>
        </Button>
        {device !== 'desktop' && (
        <>
          <SafePaymentMessage i18n={i18n} />
          {!!disclaimerLinks && !!disclaimerLinks.length && <DisclaimerLinks links={disclaimerLinks} />}
        </>
        )}
      </div>
      {device !== 'desktop' && <div style={{ height: `${height || 0}px` }} />}
    </>
  );
};

FooterComponent.propTypes = {
  i18n: PropTypes.shape({
    gettext: PropTypes.func.isRequired,
  }).isRequired,
  text: PropTypes.string,
  device: PropTypes.string,
  onClickAction: PropTypes.func,
  disabled: PropTypes.bool,
  disclaimerLinks: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      link: PropTypes.string,
    }),
  ),
};

FooterComponent.defaultProps = {
  text: 'Continuar',
  device: '',
  onClickAction: () => false,
  disclaimerLinks: [],
  disabled: false,
};

/**
 * Expose Something with i18n injection
 */
module.exports = FooterComponent;
