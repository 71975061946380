/* eslint-disable react/forbid-prop-types */
const Card = require('@andes/card');
const { CardContent } = require('@andes/card');
const React = require('react');
const PropTypes = require('prop-types');

const desktopLayout = ({ children }) => (
  <Card className="card-container">
    <CardContent>
      {children}
    </CardContent>
  </Card>
);

desktopLayout.propTypes = {
  children: PropTypes.any,
};

desktopLayout.defaultProps = {
  children: null,
};

module.exports = desktopLayout;
