const React = require('react');
const PropTypes = require('prop-types');
const UserDefaultIcon = require('../icons/UserDefault');
const UserAvatar = require('../UserAvatar');

const UserInfo = ({ imageURL, userName }) => {
  const avatar = imageURL ? <UserAvatar image={imageURL} /> : <UserDefaultIcon />;

  return (
    <div className="user-info">
      <div className="user-info__text">
        {userName}
      </div>
      {avatar}
    </div>
  );
};

UserInfo.propTypes = {
  userName: PropTypes.string,
  imageURL: PropTypes.string,
};

UserInfo.defaultProps = {
  userName: '',
  imageURL: null,
};

module.exports = UserInfo;
